<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="/" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a href="/">
      <img
        class="weewee-logo"
        src="assets/logo/Logo_Horizontal Purple Tagline.png"
        alt="logo"
      />
    </a>
    <!-- <div class="themes-change" *ngIf="userAuth.role !== 'wilaya-desk'">
        <nb-form-field nbTooltip="cette fonctionnalité est expérimentale, elle ne fonctionne que sur ces pages « Tous les packages » sélectionnez « All » pour revenir à la vue par défaut" nbTooltipStatus="control">
          <nb-icon nbPrefix icon="eye-outline" status="info"></nb-icon>
          <nb-select *ngIf="wilayas" size="large" [(selected)]="selectedWilaya" placeholder="View Wilaya State" (selectedChange)="changeWilayaView($event)">
            <nb-option *ngFor="let wilaya of wilayas" [value]="wilaya.name">{{ wilaya.name }}</nb-option>
          </nb-select>
        </nb-form-field>

      </div> -->
  </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action
      nbPopover="Hover Mode"
      nbPopoverTrigger="hover"
      nbPopoverPlacement="bottom"
      [nbPopover]="templateRef"
      class="control-item"
      class="cursor"
      title="le temps, c'est de l'argent"
    >
      {{ time }}
      <ng-template #templateRef>
        <nb-calendar
          class="calendar"
          [(date)]="date"
          [boundingMonth]="false"
        ></nb-calendar>
      </ng-template>
    </nb-action>
    <nb-action class="control-item" class="cursor" (click)="searchPage()"
      ><nb-icon icon="search-outline"></nb-icon
    ></nb-action>
    <nb-action class="full-screen" (click)="fullScreen()">
      <nb-icon *ngIf="!isFullScreen" icon="expand-outline"></nb-icon>
      <nb-icon *ngIf="isFullScreen" icon="collapse-outline"></nb-icon>
    </nb-action>
    <nb-action
      class="control-item cursor"
      title="Notification"
      nbPopover="Hover Mode"
      nbPopoverTrigger="click"
      nbPopoverPlacement="bottom"
      [nbPopover]="notificationRef"
    >
      <nb-badge
        status="danger"
        position="top right"
        [text]="notifications.length"
      ></nb-badge>
      <nb-icon icon="bell-outline"> </nb-icon>
      <ng-template class="popover" #notificationRef>
        <!-- <nb-card> -->
        <nb-list>
          <nb-list-item *ngFor="let item of notifications">
            <div class="notification-item">
              <div class="notification-content">
                  <nb-alert outline="warning" (click)="navigateTo(item.url)">
                    <div class="title">{{ item.title }}</div>
                    <div class="description">{{ item.description }}</div>
                  </nb-alert>
              </div>
            </div>
          </nb-list-item>
        </nb-list>
        <!-- </nb-card> -->
      </ng-template>
    </nb-action>
    <nb-action class="control-item" class="person">
      <nb-user
        [name]="fullName"
        [title]="role + userAuth.wilaya"
        picture="https://xsgames.co/randomusers/avatar.php?g=pixel"
        [nbContextMenu]="userMenu"
        nbContextMenuTag="user-context-menu"
        nbContextMenuTrigger="hover"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
