export class UserAuth {
    public uid: string = "";
    public displayName: string = "";
    public phoneNumber: string = "";
    public email: string = "";
    public active: string = "";
    public role: string = "";
    public wilaya: string = "";
    public fullName: string = "";
    public password?: string = "";
    public refWilaya: string = "";
    public authorizedBaladias: string[] = [];

    public static CollectionRef = 'admin-control-accounts'


    public constructor(init?: Partial<UserAuth>) {
        Object.assign(this, init);
    } //

    //
    public static roleEnums = [
        { value: "", text: "/" },
        { value: "operation", text: "Opération" }, // (OPERATION)
        { value: "wilaya-desk", text: "Bereau Wilaya" }, // (WILAYA)
        { value: "user", text: "Expéditeur" }, // (E-COMMERÇANT)
        { value: "driver", text: "Transporteur" }, // (LIVREUR)
        { value: "supervisor", text: "Supervisor" },
        { value: "coordinator", text: "Coordinator" },
        { value: "admin", text: "Admin" },
        { value: "admin-it", text: "Admin IT" },
        { value: "customer-service", text: "Customer Service"},
        { value: "accountant", text: "Accountant"},
        { value: "client-system", text: "Système client"},
        { value: "sales", text: "Ventes"},
        { value: "commercial", text: "Commercial"},
        { value: "sorting-center", text: "Centre de Traitement"},
        { value: "account-manager", text: "Account Manager"}
    ];
    public get _role() {
        return UserAuth.roleEnums.find(item => item.value == this.role)?.text || this.role;
    };
    public static activeEnums = [
        { value: "", text: "/" },
        { value: "no", text: "No" },
        { value: "yes", text: "Yes" },
    ];
    public get _active() {
        return UserAuth.activeEnums.find(item => item.value == this.active)?.text || this.active;
    };

    public get _allWilayas() {
        let wilaya = this.refWilaya || this.wilaya;
        if (wilaya == "*")
            return [
                "Adrar", "Chlef", "Laghouat", "Oum El Bouaghi", "Batna", "Béjaïa", "Biskra", "Bechar", "Blida", "Bouira",
                "Tamanrasset", "Tbessa", "Tlemcen", "Tiaret", "Tizi Ouzou", "Alger", "Djelfa", "Jijel", "Setif", "Saefda",
                "Skikda", "Sidi Bel Abbes", "Annaba", "Guelma", "Constantine", "Medea", "Mostaganem", "M'Sila", "Mascara",
                "Ouargla", "Oran", "El Bayadh", "Illizi", "Bordj Bou Arreridj", "Boumerdes", "El Tarf", "Tindouf",
                "Tissemsilt", "El Oued", "Khenchela", "Souk Ahras", "Tipaza", "Mila", "Ain Defla", "Naama",
                "Ain Temouchent", "Ghardaefa", "Relizane", "El M'ghair", "El Menia", "Ouled Djellal",
                "Bordj Baji Mokhtar", "Béni Abbès", "Timimoun", "Touggourt", "Djanet", "In Salah", "In Guezzam"
            ];
        else if (wilaya == "Alger")
            return [
                "Alger",
                "Blida",
                "Boumerdes",
                "Tipaza"
            ];
        else if (wilaya == "Jijel")
            return [
                "Jijel",
                "Mila",
                "Constantine",
                "Béjaïa",
                "Skikda"
            ];
        else
            return [
                wilaya
            ];
    };

    public get _AllWilayasLabel() {
        return this.wilaya + ' : ' + this._allWilayas.join(', ');
    };

    public get _wilayaCode() {
      switch (this.wilaya) {
        case "Adrar": return "W01-1";
        case "Chlef": return "W02-1";
        case "Laghouat": return "W03-1";
        case "Oum El Bouaghi": return "W04-1";
        case "Batna": return "W05-1";
        case "Bejaia": return "W06-1";
        case "Biskra": return "W07-1";
        case "Bechar": return "W08-1";
        case "Blida": return "W09-1";
        case "Bouira": return "W10-1";
        case "Tamanrasset": return "W11-1";
        case "Tebessa": return "W12-1";
        case "Tlemcen": return "W13-1";
        case "Tiaret": return "W14-1";
        case "Tizi Ouzou": return "W15-1";
        case "Alger": return "W16-1";
        case "Djelfa": return "W17-1";
        case "Jijel": return "W18-1";
        case "Setif": return "W19-1";
        case "Saefda": return "W20-1";
        case "Skikda": return "W21-1";
        case "Sidi Bel Abbes": return "W22-1";
        case "Annaba": return "W23-1";
        case "Guelma": return "W24-1";
        case "Constantine": return "W25-1";
        case "Medea": return "W26-1";
        case "Mostaganem": return "W27-1";
        case "M'Sila": return "W28-1";
        case "Mascara": return "W29-1";
        case "Ouargla": return "W30-1";
        case "Oran": return "W31-1";
        case "El Bayadh": return "W32-1";
        case "Illizi": return "W33-1";
        case "Bordj Bou Arreridj": return "W34-1";
        case "Boumerdes": return "W35-1";
        case "El Tarf": return "W36-1";
        case "Tindouf": return "W37-1";
        case "Tissemsilt": return "W38-1";
        case "El Oued": return "W39-1";
        case "Khenchela": return "W40-1";
        case "Souk Ahras": return "W41-1";
        case "Tipaza": return "W42-1";
        case "Mila": return "W43-1";
        case "Ain Defla": return "W44-1";
        case "Naama": return "W45-1";
        case "Ain Temouchent": return "W46-1";
        case "Ghardaefa": return "W47-1";
        case "Relizane": return "W48-1";
        case "El M'ghair": return "W49-1";
        case "El Menia": return "W50-1";
        case "Ouled Djellal": return "W51-1";
        case "Bordj Baji Mokhtar": return "W52-1";
        case "Béni Abbès": return "W53-1";
        case "Timimoun": return "W54-1";
        case "Touggourt": return "W55-1";
        case "Djanet": return "W56-1";
        case "In Salah": return "W57-1";
        case "In Guezzam": return "W58-1";
        default: return "W00-1";
      }
    }

    public get _wilayaCodeLabel() {
      switch (this.wilaya) {
        case "Adrar": return "WEE 01";
        case "Chlef": return "WEE 02";
        case "Laghouat": return "WEE 03";
        case "Oum El Bouaghi": return "WEE 04";
        case "Batna": return "WEE 05";
        case "Béjaïa": return "WEE 06";
        case "Biskra": return "WEE 07";
        case "Bechar": return "WEE 08";
        case "Blida": return "WEE 09";
        case "Bouira": return "WEE 10";
        case "Tamanrasset": return "WEE 11";
        case "Tebessa": return "WEE 12";
        case "Tlemcen": return "WEE 13";
        case "Tiaret": return "WEE 14";
        case "Tizi Ouzou": return "WEE 15";
        case "Alger": return "WEE 16";
        case "Djelfa": return "WEE 17";
        case "Jijel": return "WEE 18";
        case "Setif": return "WEE 19";
        case "Saefda": return "WEE 20";
        case "Skikda": return "WEE 21";
        case "Sidi Bel Abbes": return "WEE 22";
        case "Annaba": return "WEE 23";
        case "Guelma": return "WEE 24";
        case "Constantine": return "WEE 25";
        case "Medea": return "WEE 26";
        case "Mostaganem": return "WEE 27";
        case "M'Sila": return "WEE 28";
        case "Mascara": return "WEE 29";
        case "Ouargla": return "WEE 30";
        case "Oran": return "WEE 31";
        case "El Bayadh": return "WEE 32";
        case "Illizi": return "WEE 33";
        case "Bordj Bou Arreridj": return "WEE 34";
        case "Boumerdes": return "WEE 35";
        case "El Tarf": return "WEE 36";
        case "Tindouf": return "WEE 37";
        case "Tissemsilt": return "WEE 38";
        case "El Oued": return "WEE 39";
        case "Khenchela": return "WEE 40";
        case "Souk Ahras": return "WEE 41";
        case "Tipaza": return "WEE 42";
        case "Mila": return "WEE 43";
        case "Ain Defla": return "WEE 44";
        case "Naama": return "WEE 45";
        case "Ain Temouchent": return "WEE 46";
        case "Ghardaefa": return "WEE 47";
        case "Relizane": return "WEE 48";
        case "El M'ghair": return "WEE 49";
        case "El Menia": return "WEE 50";
        case "Ouled Djellal": return "WEE 51";
        case "Bordj Baji Mokhtar": return "WEE 52";
        case "Béni Abbès": return "WEE 53";
        case "Timimoun": return "WEE 54";
        case "Touggourt": return "WEE 55";
        case "Djanet": return "WEE 56";
        case "In Salah": return "WEE 57";
        case "In Guezzam": return "WEE 58";
        default: return "W00-1";
      }
    }


    toFirebase() {
      return {
        uid: this.uid,
        phoneNumber: this.phoneNumber,
        email: this.email,
        role: this.role,
        wilaya: this.wilaya,
        fullName: this.fullName,
        displayName: this.displayName,
        active: this.active,
        refWilaya: this._wilayaCode
      }
    }
};




